* {
  box-sizing: border-box;
}

html {
  scroll-behavior: auto;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.gooeey {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 142px;
  height: 40px;
  margin: -20px 0 0 -71px;
  /* filter: contrast(20); */
}

.gooey {
  position: absolute;
  top: 50%;
  left: 75%;
  width: 142px;
  height: 40px;
  margin: -20px 0 0 -71px;
  /* filter: contrast(20); */
}
.dot {
  position: absolute;
  width: 10px;
  height: 10px;
  top: 12px;
  left: 15px;
  background: #dc4d04;
  border-radius: 50%;
  transform: translateX(0);
  animation: dot 2.8s infinite;
}
.dots {
  transform: translateX(0);
  margin-top: 12px;
  margin-left: 31px;
  animation: dots 2.8s infinite;
}
.dots > span {
  display: block;
  float: left;
  width: 10px;
  height: 10px;
  margin-left: 16px;
  background: #dc4d04;
  border-radius: 50%;
}
@keyframes dot {
  50% {
    transform: translateX(96px);
  }
}

@keyframes dots {
  50% {
    transform: translateX(-31px);
  }
}

.lds-ctn {
  width: 100%;
  height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.lds-ring {
  display: inline-block;
  position: relative;
  width: 40px;
  height: 40px;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 34px;
  height: 34px;
  margin: 8px;
  border: 3px solid #dc4d04;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #dc4d04 transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.toast-ctn {
  background-color: #353434;
  color: #fff;
}
