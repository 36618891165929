.new__header__ctn {
  width: 100%;
  max-width: 100%;
}

.new__header__ctn a {
  color: #282828;
  transition: all 0.3s ease-in-out;
  font-size: 14px;
}

.new__header__ctn a:hover {
  color: #dc4d04;
}

.new__header__ctn p {
  margin: 0;
}

.new__header__upper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 10px;
  padding-left: 10px;
}

.new__header__upper .whatsapp-link {
  display: flex;
}

.whatsapp-link .whatsapp__icon {
  width: 20px;
  margin-right: 10px;
  object-fit: contain;
}

.header__sub__links {
  display: flex;
  gap: 1.5rem;
  list-style: none;
}

.cart__icon__wrapper {
  display: flex;
  gap: 4px;
  position: relative;
}

.cart__icon__wrapper svg {
  width: 1rem;
}

.cart__icon__wrapper span {
  position: absolute;
  display: flex !important;
  justify-content: center;
  align-items: center;
  padding: 0 6px;
  height: 20px;
  font-size: 10px;
  top: -10px;
  right: -10px;
  text-align: center;
  border-radius: 50%;
  background-color: #f8d4c2;
}

.new__header__lower {
  background-color: #fff;
  padding-top: 15px;
  padding-bottom: 15px;
}

.new__header__lower__wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 4rem;
}

.new__header__lower .new__header__logo__ctn {
  display: flex;
  align-items: center;
  gap: 2rem;
}

.new__header__logo__ctn .new__header__logo__wrapper {
  width: 100px;
}

.new__header__logo__wrapper img {
  height: auto;
  width: 100%;
  object-fit: contain;
}

.new__header__logo__ctn .category__meun__btn {
  position: relative;
}

.category__meun__btn button {
  background: #fff;
  font-size: 22px;
  cursor: pointer;
  border: none;
}

.category__dropdown__ctn {
  position: absolute;
  top: 100%;
  left: -100%;
  right: 0;
  z-index: 1000;
  background-color: #fff;
  width: fit-content;
  padding: 1rem 0;
  display: flex;
  flex-direction: column;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.05);
  border-radius: 0.5rem;
}

.category__dropdown__content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
  padding: 0.5rem 1rem;
}

.category__dropdown__content:hover {
  background-color: #f1efef;
}

.category__dropdown__wrap {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.category__dropdown__content img {
  width: 10px !important;
  height: 10px !important;
}

.category__dropdown__content p,
.category__dropdown__content span {
  font-size: 14px;
  white-space: nowrap;
}

.header__search__ctn {
  width: 51%;
  position: relative;
  margin: 0 auto;
}

.header__search__ctn input {
  width: 85%;
  height: 44px;
  padding-left: 25px;
  padding-right: 60px;
  font-size: 14px;
  font-weight: 500;
  border: 1px solid #dc4d04;
  border-radius: 20px 0 0 20px;
  background: transparent;
  color: #282828;
  outline: none;
}

.header__search__ctn button {
  width: auto;
  height: 44px;
  position: absolute;
  top: 50%;
  right: 1px;
  translate: 0 -50%;
  z-index: 1;
  font-size: 16px;
  font-weight: 500;
  padding: 0 25px;
  height: 44px;
  border-radius: 0;
  border-top-right-radius: 20px !important;
  border-bottom-right-radius: 20px !important;
  background: #dc4d04;
}

.header___dropstart__ctn {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.header___dropstart__ctn .header___dropstart {
  display: flex;
  align-items: center;
  gap: 6px;
  font-size: 14px;
}

.header___dropstart span {
  font-size: 18px;
  margin-bottom: 4px;
}

.header___dropstart p {
  font-size: 14px !important;
  margin-bottom: 0;
}

.dropstart__top {
  top: 100% !important;
  right: -100% !important;
}

.mobile__cart__wrapper {
  display: none;
}
.header___dropstart__ctn .mobile-search a {
  display: none;
}

@media (max-width: 800px) {
  .new__header__upper {
    display: none;
  }
  .header__search__ctn {
    display: none;
  }
  .header___dropstart__ctn .mobile-search a {
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }
  .currency__header {
    display: none;
  }
  .mobile__cart__wrapper {
    display: flex;
  }
}

@media (max-width: 500px) {
  .header___dropstart__ctn .mobile-search a span,
  .header___dropstart span,
  .header___dropstart p,
  .mobile__cart__wrapper p {
    display: none;
  }

  .new__header__logo__ctn .category__meun__btn {
    display: none;
  }
}
