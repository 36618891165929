.position__relative {
  position: relative;
}

.section-head a h2 {
  transition: all 0.3s ease-out;
}

.section-head a h2:hover {
  text-decoration: underline;
}

.aliexpress-ctn {
  position: absolute;
  top: 7%;
  right: 5%;
}

.aliexpress-ctn img {
  width: 35px !important;
  height: 30px !important;
  object-fit: contain;
  border-radius: 5px;
  z-index: 5;
}
